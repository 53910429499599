var $ = require('../internals/export');
var log1p = require('../internals/math-log1p');
var nativeAcosh = Math.acosh;
var log = Math.log;
var sqrt = Math.sqrt;
var LN2 = Math.LN2;
var FORCED = !nativeAcosh
// V8 bug: https://code.google.com/p/v8/issues/detail?id=3509
|| Math.floor(nativeAcosh(Number.MAX_VALUE)) != 710
// Tor Browser bug: Math.acosh(Infinity) -> NaN
|| nativeAcosh(Infinity) != Infinity;

// `Math.acosh` method
// https://tc39.github.io/ecma262/#sec-math.acosh
$({
  target: 'Math',
  stat: true,
  forced: FORCED
}, {
  acosh: function acosh(x) {
    return (x = +x) < 1 ? NaN : x > 94906265.62425156 ? log(x) + LN2 : log1p(x - 1 + sqrt(x - 1) * sqrt(x + 1));
  }
});