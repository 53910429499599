var $ = require('../internals/export');
var sign = require('../internals/math-sign');

// `Math.sign` method
// https://tc39.github.io/ecma262/#sec-math.sign
$({
  target: 'Math',
  stat: true
}, {
  sign: sign
});