var $ = require('../internals/export');
var min = Math.min;
var max = Math.max;

// `Math.clamp` method
// https://rwaldron.github.io/proposal-math-extensions/
$({
  target: 'Math',
  stat: true
}, {
  clamp: function clamp(x, lower, upper) {
    return min(upper, max(lower, x));
  }
});