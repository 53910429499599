var $ = require('../internals/export');
var of = require('../internals/collection-of');

// `Set.of` method
// https://tc39.github.io/proposal-setmap-offrom/#sec-set.of
$({
  target: 'Set',
  stat: true
}, {
  of: of
});