'use strict';

// https://github.com/tc39/proposal-iterator-helpers
var aFunction = require('../internals/a-function');
var anObject = require('../internals/an-object');
var getBuiltIn = require('../internals/get-built-in');
var Promise = getBuiltIn('Promise');
var push = [].push;
var createMethod = function (TYPE) {
  var IS_TO_ARRAY = TYPE == 0;
  var IS_FOR_EACH = TYPE == 1;
  var IS_EVERY = TYPE == 2;
  var IS_SOME = TYPE == 3;
  return function (iterator, fn) {
    anObject(iterator);
    var next = aFunction(iterator.next);
    var array = IS_TO_ARRAY ? [] : undefined;
    if (!IS_TO_ARRAY) aFunction(fn);
    return new Promise(function (resolve, reject) {
      var loop = function () {
        try {
          Promise.resolve(anObject(next.call(iterator))).then(function (step) {
            try {
              if (anObject(step).done) {
                resolve(IS_TO_ARRAY ? array : IS_SOME ? false : IS_EVERY || undefined);
              } else {
                var value = step.value;
                if (IS_TO_ARRAY) {
                  push.call(array, value);
                  loop();
                } else {
                  Promise.resolve(fn(value)).then(function (result) {
                    if (IS_FOR_EACH) {
                      loop();
                    } else if (IS_EVERY) {
                      result ? loop() : resolve(false);
                    } else {
                      result ? resolve(IS_SOME || value) : loop();
                    }
                  }, reject);
                }
              }
            } catch (err) {
              reject(err);
            }
          }, reject);
        } catch (error) {
          reject(error);
        }
      };
      loop();
    });
  };
};
module.exports = {
  toArray: createMethod(0),
  forEach: createMethod(1),
  every: createMethod(2),
  some: createMethod(3),
  find: createMethod(4)
};