'use strict';

var path = require('../internals/path');
var aFunction = require('../internals/a-function');
var anObject = require('../internals/an-object');
var create = require('../internals/object-create');
var createNonEnumerableProperty = require('../internals/create-non-enumerable-property');
var redefineAll = require('../internals/redefine-all');
var wellKnownSymbol = require('../internals/well-known-symbol');
var InternalStateModule = require('../internals/internal-state');
var getBuiltIn = require('../internals/get-built-in');
var Promise = getBuiltIn('Promise');
var setInternalState = InternalStateModule.set;
var getInternalState = InternalStateModule.get;
var TO_STRING_TAG = wellKnownSymbol('toStringTag');
var $return = function (value) {
  var iterator = getInternalState(this).iterator;
  var $$return = iterator['return'];
  return $$return === undefined ? Promise.resolve({
    done: true,
    value: value
  }) : anObject($$return.call(iterator, value));
};
var $throw = function (value) {
  var iterator = getInternalState(this).iterator;
  var $$throw = iterator['throw'];
  return $$throw === undefined ? Promise.reject(value) : $$throw.call(iterator, value);
};
module.exports = function (nextHandler, IS_ITERATOR) {
  var AsyncIteratorProxy = function AsyncIterator(state) {
    state.next = aFunction(state.iterator.next);
    state.done = false;
    setInternalState(this, state);
  };
  AsyncIteratorProxy.prototype = redefineAll(create(path.AsyncIterator.prototype), {
    next: function next(arg) {
      var state = getInternalState(this);
      if (state.done) return Promise.resolve({
        done: true,
        value: undefined
      });
      try {
        return Promise.resolve(anObject(nextHandler.call(state, arg, Promise)));
      } catch (error) {
        return Promise.reject(error);
      }
    },
    'return': $return,
    'throw': $throw
  });
  if (!IS_ITERATOR) {
    createNonEnumerableProperty(AsyncIteratorProxy.prototype, TO_STRING_TAG, 'Generator');
  }
  return AsyncIteratorProxy;
};