'use strict';

var $ = require('../internals/export');
var $trimStart = require('../internals/string-trim').start;
var forcedStringTrimMethod = require('../internals/string-trim-forced');
var FORCED = forcedStringTrimMethod('trimStart');
var trimStart = FORCED ? function trimStart() {
  return $trimStart(this);
} : ''.trimStart;

// `String.prototype.{ trimStart, trimLeft }` methods
// https://github.com/tc39/ecmascript-string-left-right-trim
$({
  target: 'String',
  proto: true,
  forced: FORCED
}, {
  trimStart: trimStart,
  trimLeft: trimStart
});