var $ = require('../internals/export');
var from = require('../internals/collection-from');

// `WeakSet.from` method
// https://tc39.github.io/proposal-setmap-offrom/#sec-weakset.from
$({
  target: 'WeakSet',
  stat: true
}, {
  from: from
});