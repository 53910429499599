var $ = require('../internals/export');
var parseFloatImplementation = require('../internals/number-parse-float');

// `parseFloat` method
// https://tc39.github.io/ecma262/#sec-parsefloat-string
$({
  global: true,
  forced: parseFloat != parseFloatImplementation
}, {
  parseFloat: parseFloatImplementation
});