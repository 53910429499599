'use strict';

// https://github.com/tc39/proposal-iterator-helpers
var $ = require('../internals/export');
var anObject = require('../internals/an-object');
var toPositiveInteger = require('../internals/to-positive-integer');
var createAsyncIteratorProxy = require('../internals/async-iterator-create-proxy');
var AsyncIteratorProxy = createAsyncIteratorProxy(function (arg) {
  if (!this.remaining--) {
    this.done = true;
    return {
      done: true,
      value: undefined
    };
  }
  return this.next.call(this.iterator, arg);
});
$({
  target: 'AsyncIterator',
  proto: true,
  real: true
}, {
  take: function take(limit) {
    return new AsyncIteratorProxy({
      iterator: anObject(this),
      remaining: toPositiveInteger(limit)
    });
  }
});