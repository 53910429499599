'use strict';

// https://github.com/tc39/proposal-iterator-helpers
var $ = require('../internals/export');
var aFunction = require('../internals/a-function');
var anObject = require('../internals/an-object');
var createAsyncIteratorProxy = require('../internals/async-iterator-create-proxy');
var getAsyncIteratorMethod = require('../internals/get-async-iterator-method');
var AsyncIteratorProxy = createAsyncIteratorProxy(function (arg, Promise) {
  var state = this;
  var mapper = state.mapper;
  var innerIterator, iteratorMethod;
  return new Promise(function (resolve, reject) {
    var outerLoop = function () {
      try {
        Promise.resolve(anObject(state.next.call(state.iterator, arg))).then(function (step) {
          try {
            if (anObject(step).done) {
              state.done = true;
              resolve({
                done: true,
                value: undefined
              });
            } else {
              Promise.resolve(mapper(step.value)).then(function (mapped) {
                try {
                  iteratorMethod = getAsyncIteratorMethod(mapped);
                  if (iteratorMethod !== undefined) {
                    state.innerIterator = innerIterator = anObject(iteratorMethod.call(mapped));
                    state.innerNext = aFunction(innerIterator.next);
                    return innerLoop();
                  }
                  reject(TypeError('.flatMap callback should return an iterable object'));
                } catch (error2) {
                  reject(error2);
                }
              }, reject);
            }
          } catch (error1) {
            reject(error1);
          }
        }, reject);
      } catch (error) {
        reject(error);
      }
    };
    var innerLoop = function () {
      if (innerIterator = state.innerIterator) {
        try {
          Promise.resolve(anObject(state.innerNext.call(innerIterator))).then(function (result) {
            try {
              if (anObject(result).done) {
                state.innerIterator = state.innerNext = null;
                outerLoop();
              } else resolve({
                done: false,
                value: result.value
              });
            } catch (error1) {
              reject(error1);
            }
          }, reject);
        } catch (error) {
          reject(error);
        }
      } else outerLoop();
    };
    innerLoop();
  });
});
$({
  target: 'AsyncIterator',
  proto: true,
  real: true
}, {
  flatMap: function flatMap(mapper) {
    return new AsyncIteratorProxy({
      iterator: anObject(this),
      mapper: aFunction(mapper),
      innerIterator: null,
      innerNext: null
    });
  }
});