var $ = require('../internals/export');
var log = Math.log;
var LN2 = Math.LN2;

// `Math.log2` method
// https://tc39.github.io/ecma262/#sec-math.log2
$({
  target: 'Math',
  stat: true
}, {
  log2: function log2(x) {
    return log(x) / LN2;
  }
});