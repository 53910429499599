var $ = require('../internals/export');
var expm1 = require('../internals/math-expm1');
var nativeCosh = Math.cosh;
var abs = Math.abs;
var E = Math.E;

// `Math.cosh` method
// https://tc39.github.io/ecma262/#sec-math.cosh
$({
  target: 'Math',
  stat: true,
  forced: !nativeCosh || nativeCosh(710) === Infinity
}, {
  cosh: function cosh(x) {
    var t = expm1(abs(x) - 1) + 1;
    return (t + 1 / (t * E * E)) * (E / 2);
  }
});