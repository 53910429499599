'use strict';

var $ = require('../internals/export');
var exec = require('../internals/regexp-exec');
$({
  target: 'RegExp',
  proto: true,
  forced: /./.exec !== exec
}, {
  exec: exec
});