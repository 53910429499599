var $ = require('../internals/export');
var ArrayBufferModule = require('../internals/array-buffer');
var NATIVE_ARRAY_BUFFER = require('../internals/array-buffer-native');

// `DataView` constructor
// https://tc39.github.io/ecma262/#sec-dataview-constructor
$({
  global: true,
  forced: !NATIVE_ARRAY_BUFFER
}, {
  DataView: ArrayBufferModule.DataView
});