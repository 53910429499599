var $ = require('../internals/export');
var isObject = require('../internals/is-object');
var onFreeze = require('../internals/internal-metadata').onFreeze;
var FREEZING = require('../internals/freezing');
var fails = require('../internals/fails');
var nativeSeal = Object.seal;
var FAILS_ON_PRIMITIVES = fails(function () {
  nativeSeal(1);
});

// `Object.seal` method
// https://tc39.github.io/ecma262/#sec-object.seal
$({
  target: 'Object',
  stat: true,
  forced: FAILS_ON_PRIMITIVES,
  sham: !FREEZING
}, {
  seal: function seal(it) {
    return nativeSeal && isObject(it) ? nativeSeal(onFreeze(it)) : it;
  }
});