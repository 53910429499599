var $ = require('../internals/export');
var log = Math.log;
var LOG10E = Math.LOG10E;

// `Math.log10` method
// https://tc39.github.io/ecma262/#sec-math.log10
$({
  target: 'Math',
  stat: true
}, {
  log10: function log10(x) {
    return log(x) * LOG10E;
  }
});