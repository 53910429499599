var $ = require('../internals/export');
var RAD_PER_DEG = 180 / Math.PI;

// `Math.degrees` method
// https://rwaldron.github.io/proposal-math-extensions/
$({
  target: 'Math',
  stat: true
}, {
  degrees: function degrees(radians) {
    return radians * RAD_PER_DEG;
  }
});