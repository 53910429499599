var $ = require('../internals/export');
var from = require('../internals/collection-from');

// `Set.from` method
// https://tc39.github.io/proposal-setmap-offrom/#sec-set.from
$({
  target: 'Set',
  stat: true
}, {
  from: from
});