'use strict';

// https://github.com/tc39/proposal-iterator-helpers
var $ = require('../internals/export');
var aFunction = require('../internals/a-function');
var anObject = require('../internals/an-object');
var getIteratorMethod = require('../internals/get-iterator-method');
var createIteratorProxy = require('../internals/iterator-create-proxy');
var callWithSafeIterationClosing = require('../internals/call-with-safe-iteration-closing');
var IteratorProxy = createIteratorProxy(function (arg) {
  var iterator = this.iterator;
  var result, mapped, iteratorMethod, innerIterator;
  while (true) {
    if (innerIterator = this.innerIterator) {
      result = anObject(this.innerNext.call(innerIterator));
      if (!result.done) return result.value;
      this.innerIterator = this.innerNext = null;
    }
    result = anObject(this.next.call(iterator, arg));
    if (this.done = !!result.done) return;
    mapped = callWithSafeIterationClosing(iterator, this.mapper, result.value);
    iteratorMethod = getIteratorMethod(mapped);
    if (iteratorMethod === undefined) {
      throw TypeError('.flatMap callback should return an iterable object');
    }
    this.innerIterator = innerIterator = anObject(iteratorMethod.call(mapped));
    this.innerNext = aFunction(innerIterator.next);
  }
});
$({
  target: 'Iterator',
  proto: true,
  real: true
}, {
  flatMap: function flatMap(mapper) {
    return new IteratorProxy({
      iterator: anObject(this),
      mapper: aFunction(mapper),
      innerIterator: null,
      innerNext: null
    });
  }
});