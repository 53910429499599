'use strict';

var InternalStateModule = require('../internals/internal-state');
var createIteratorConstructor = require('../internals/create-iterator-constructor');
var has = require('../internals/has');
var objectKeys = require('../internals/object-keys');
var toObject = require('../internals/to-object');
var OBJECT_ITERATOR = 'Object Iterator';
var setInternalState = InternalStateModule.set;
var getInternalState = InternalStateModule.getterFor(OBJECT_ITERATOR);
module.exports = createIteratorConstructor(function ObjectIterator(source, mode) {
  var object = toObject(source);
  setInternalState(this, {
    type: OBJECT_ITERATOR,
    mode: mode,
    object: object,
    keys: objectKeys(object),
    index: 0
  });
}, 'Object', function next() {
  var state = getInternalState(this);
  var keys = state.keys;
  while (true) {
    if (keys === null || state.index >= keys.length) {
      state.object = state.keys = null;
      return {
        value: undefined,
        done: true
      };
    }
    var key = keys[state.index++];
    var object = state.object;
    if (!has(object, key)) continue;
    switch (state.mode) {
      case 'keys':
        return {
          value: key,
          done: false
        };
      case 'values':
        return {
          value: object[key],
          done: false
        };
    } /* entries */
    return {
      value: [key, object[key]],
      done: false
    };
  }
});